var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.region === 'PAKISTAN')?_c('div',[_c('div',{staticClass:"waiting-bar",style:(_vm.progressStyle)},[_c('div',{staticClass:"waiting-bar__progress",style:(("width:" + _vm.progressWidth + "%"))})])]):_vm._e(),(_vm.lastSeenUI !== '' && _vm.region === 'PAKISTAN')?_c('div',{staticClass:"last_seen"},[_c('div',{staticClass:"heading"},[_vm._v("Last Seen")]),_c('div',{staticClass:"text"},[_c('timeago',{attrs:{"datetime":this.lastSeenUI}})],1)]):_vm._e(),(_vm.region === 'PAKISTAN')?_c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"500px"},attrs:{"center":{ lat: this.deliverylat, lng: this.deliverylng },"zoom":13,"map-type-id":"terrain"}},[_c('GmapMarker',{key:"abcd",attrs:{"position":{ lat: _vm.lat, lng: _vm.lng },"clickable":true,"draggable":true,"icon":_vm.getImgUrl('ic_car'),"anchor":"0.5,0.5"}}),_c('GmapMarker',{key:"storeA",attrs:{"position":{ lat: this.storelat, lng: this.storelng },"icon":this.icon,"anchor":"0.5,0.5"}}),_c('GmapMarker',{key:"storeB",attrs:{"position":{ lat: this.deliverylat, lng: this.deliverylng },"icon":{
          url: _vm.getImgUrl('manpic'),
          scaledSize: { width: 35, height: 35 }
        },"anchor":"0.5,0.5"}}),_c('GmapMarker',{key:"storeC",attrs:{"position":{ lat: this.locacceptedLat, lng: this.locacceptedLng },"icon":{
          url: _vm.getImgUrl('successgreenIcon'),
          scaledSize: { width: 35, height: 35 }
        },"anchor":"0.5,0.5"}})],1)],1):_vm._e(),(_vm.region === 'US')?_c('div',{attrs:{"id":"app"}},[_c('mapbox',{attrs:{"access-token":"pk.eyJ1IjoiYWJkdWxoYWNlZWIiLCJhIjoiY2tlbDY5aHN1MGpoNDJxbncwYW1uNmt1eiJ9.XD9cx0TzsWPt8ORZ5DxKKw","map-options":{
        style: 'mapbox://styles/mapbox/light-v9',
        center: [-96, 37.8],
        zoom: 3
      }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }