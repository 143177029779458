<template>
  <div>
    <div v-if="region === 'PAKISTAN'">
      <div class="waiting-bar" :style="progressStyle">
        <div
          class="waiting-bar__progress"
          :style="`width:${progressWidth}%`"
        ></div>
      </div>
    </div>

    <div v-if="lastSeenUI !== '' && region === 'PAKISTAN'" class="last_seen">
      <div class="heading">Last Seen</div>
      <div class="text">
        <timeago :datetime="this.lastSeenUI"></timeago>
      </div>
    </div>
    <div v-if="region === 'PAKISTAN'">
      <GmapMap
        :center="{ lat: this.deliverylat, lng: this.deliverylng }"
        :zoom="13"
        map-type-id="terrain"
        style="width: 100%; height: 500px"
      >
        <GmapMarker
          key="abcd"
          :position="{ lat, lng }"
          :clickable="true"
          :draggable="true"
          :icon="getImgUrl('ic_car')"
          anchor="0.5,0.5"
        />

        <GmapMarker
          key="storeA"
          :position="{ lat: this.storelat, lng: this.storelng }"
          :icon="this.icon"
          anchor="0.5,0.5"
        />
        <GmapMarker
          key="storeB"
          :position="{ lat: this.deliverylat, lng: this.deliverylng }"
          :icon="{
            url: getImgUrl('manpic'),
            scaledSize: { width: 35, height: 35 }
          }"
          anchor="0.5,0.5"
        />
        <GmapMarker
          key="storeC"
          :position="{ lat: this.locacceptedLat, lng: this.locacceptedLng }"
          :icon="{
            url: getImgUrl('successgreenIcon'),
            scaledSize: { width: 35, height: 35 }
          }"
          anchor="0.5,0.5"
        />
      </GmapMap>
    </div>

    <div v-if="region === 'US'" id="app">
      <mapbox
        access-token="pk.eyJ1IjoiYWJkdWxoYWNlZWIiLCJhIjoiY2tlbDY5aHN1MGpoNDJxbncwYW1uNmt1eiJ9.XD9cx0TzsWPt8ORZ5DxKKw"
        :map-options="{
          style: 'mapbox://styles/mapbox/light-v9',
          center: [-96, 37.8],
          zoom: 3
        }"
      />
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { mapState } from "vuex";
import Mapbox from "mapbox-gl-vue";

export default {
  components: { Mapbox },
  props: {
    id: String,
    storeAddress: Array,
    deliveryAddress: Object,
    locationaccept: Object,
    deliveryStatus: String,
    lastseen: Number
  },
  data() {
    return {
      lat: 0,
      lng: 0,
      storelat: 0,
      storelng: 0,
      deliverylat: 0,
      deliverylng: 0,
      locacceptedLat: 0,
      locacceptedLng: 0,
      orderDelStatus: "",
      lastSeenUI: "",
      icon: {
        url:
          "https://baskyt-s3.s3.amazonaws.com/vendorImages/1000X1000/1621340080288-logoMain.png",
        scaledSize: { width: 28, height: 35 },
        labelOrigin: { x: 16, y: -10 }
      },
      progressWidth: 0,
      progressStyle:
        "position: absolute;top: 0;left: 0;width: 100%;z-index: 1;margin: 0; "
    };
  },
  methods: {
    getImgUrl(image) {
      const images = require.context("@/assets/images/", false, /\.png$/);
      return images(`./${image}.png`);
    },
    startEvent(start, end) {
      const int = setInterval(() => {
        // calculate progressWidth periodically
        const now = new Date().valueOf();
        if (now >= end) {
          // if finished
          this.progressWidth = 100;
          clearInterval(int);
          return;
        }
        this.progressWidth = ((now - start) / (end - start)) * 100;
      }, 10);
    }
  },
  watch: {
    progressWidth(val) {
      if (val === 100 && this.orderDelStatus !== "Delivered") {
        const start = new Date().valueOf();
        this.startEvent(start, start + 20000);
      }
    }
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },
  mounted() {
    console.log(this.deliveryAddress);
    //console.log(this.locationaccept);
    this.storelat = this.storeAddress[0].address.coordinates[1];
    this.storelng = this.storeAddress[0].address.coordinates[0];
    this.deliverylat = this.deliveryAddress.coordinates[1];
    this.deliverylng = this.deliveryAddress.coordinates[0];
    if (this.locationaccept === "undefined") {
      console.log("undefined test for coordinates");
    }
    this.locacceptedLat = this.locationaccept.coordinates[1];
    this.locacceptedLng = this.locationaccept.coordinates[0];
    this.orderDelStatus = this.deliveryStatus;
    if (this.orderDelStatus === "Delivered") {
      console.log("order already delivered");
    } else {
      this.lastSeenUI = this.lastseen;
      const start = new Date().valueOf();
      this.startEvent(start, start + 20000);
      ApiService.get(`/rider/getLocation/${this.id}`)
        .then(({ data: response }) => {
          if (response.success) {
            this.lat = response.location.coordinates[1];
            this.lng = response.location.coordinates[0];
          }
        })
        .catch(error => {
          console.log(error);
        });
      setInterval(() => {
        ApiService.get(`/rider/getLocation/${this.id}`)
          .then(({ data: response }) => {
            if (response.success) {
              this.lat = response.location.coordinates[1];
              this.lng = response.location.coordinates[0];
            }
          })
          .catch(error => {
            console.log(error);
          });
      }, 20000);
    }
  }
};
</script>
<style lang="scss" scoped>
.waiting-bar {
  height: 5px;
  background: #e5dede;
  position: relative;
  margin-left: -20px;
}
.waiting-bar__progress {
  background-color: #1da74b;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}
.last_seen {
  padding: 3.5px 0;
  display: flex;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  padding: 2.5px 5px;

  .heading {
    width: 10%;
    font-weight: 600;
  }
  .text {
    // font-size: 8px;
    width: 60%;
  }
  #map {
    width: 100%;
    height: 500px;
  }
}
</style>
